html {
    --color-background: #113371;
    --color-primary: rebeccapurple;
  }
.navbar__link , .navbar-light .core-navbar.navbar-nav .nav-link{
    text-decoration: none;
    margin-left: 15px;
    display: inline-flex;
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
    width: 135px;
    height: 31px;
    justify-content: center;
    align-items: center;
    .active {
        background-color: #36b2e9;
        color: #ffffff;
        font-weight: 400;
        width: 141px;
        height: 31px;
        text-decoration: none;
        cursor: pointer;
    }
    :hover {
        text-decoration: none;
        color: #36b2e9;
        transition: 0.3s;
        width: 141px;
        height: 31px;
    }
}

.navbar__link:hover{
    text-decoration: none;
    color: #36b2e9;
    transition: 0.3s;
}

.navbar__link--active, .navbar-light .core-navbar.navbar-nav .nav-link--active {
    background-color: #36b2e9;
    color: #ffffff;
    font-weight: 400;
    width: 141px;
    height: 31px;
    text-decoration: none;
    cursor: pointer;
}

.navbar__link--active:hover {
    background-color: #36b2e9;
    color: #ffffff;
}
.navbar-wapper nav{
    // position: relative;
    // left: 0;
    // right: 0;
    // width: 100%;
    height: 60px;
    // display: flex;
    justify-content: flex-start;
    align-items: center;
    // background-color: var(--color-background);
    opacity: 1;
}
.navbar-wapper a{
    background-color: #e2e6ea;
    border-color: #dae0e5;
    :hover {
        text-decoration: none;
        color: #212529;
    }
    padding: 4px 8px;
    border-width: 1px;
    border-radius: 5px;
    position: relative;
    color: var(--color-background);
}